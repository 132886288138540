import Fuse from 'fuse.js'
import { getSimpleLocale } from '@connections/utils'

const toKeyValue = (result, { name, value }) => {
    result[value] = name
    return result
}

const toKeyValueObject = (result, object) => {
    result[object.value] = object
    return result
}

const getContinentZoomLevel = (code) => {
    if (code === 'EU') {
        return 4
    }
    if (code === 'ME') {
        return 6
    }
    if (code === 'AU') {
        return 4
    }
    return 3
}

const getContinentGeoPoint = (code) => {
    if (code === 'AF') {
        return {
            latitude: 6.140024273267412,
            longitude: 22.60536586666266,
        }
    }
    if (code === 'EU') {
        return {
            latitude: 48.69060753078958,
            longitude: 6.609273270983831,
        }
    }
    if (code === 'ME') {
        return {
            latitude: 30.750818789949225,
            longitude: 33.679583817517226,
        }
    }
    if (code === 'NA') {
        return {
            latitude: 49.23963022118395,
            longitude: -99.98733476356018,
        }
    }
    if (code === 'SA') {
        return {
            latitude: -18.221846938249644,
            longitude: -59.34447888582702,
        }
    }
    if (code === 'AS') {
        return {
            latitude: 23.563497726822032,
            longitude: 102.76161008237193,
        }
    }
    if (code === 'AU') {
        return {
            latitude: -34.016683621237846,
            longitude: 147.2342649243537,
        }
    }
    return null
}

export default async function fetchCodes(fullLocale) {
    const locale = getSimpleLocale(fullLocale)
    const [
        airlinesResponse,
        airportsResponse,
        destinationCitiesResponse,
        destinationContinentsResponse,
        destinationCountriesResponse,
        destinationRegionsResponse,
        destinationStatesResponse,
        shopLocationsResponse,
        countriesResponse,
    ] = await Promise.all([
        fetch('/codes/airlines.json'),
        fetch(`/codes/airports-${locale}.json`),
        fetch(`/codes/destination-cities-${locale}.json`),
        fetch(`/codes/destination-continents-${locale}.json`),
        fetch(`/codes/destination-countries-${locale}.json`),
        fetch(`/codes/destination-regions-${locale}.json`),
        fetch(`/codes/destination-states-${locale}.json`),
        fetch(`/codes/shop-locations-${locale}.json`),
        fetch(`/codes/countries-${locale}.json`),
    ])
    const [
        airlinesJson,
        airportsJson,
        destinationCitiesJson,
        destinationContinentsJson,
        destinationCountriesJson,
        destinationRegionsJson,
        destinationStatesJson,
        shopLocationsJson,
        countriesJson,
    ] = await Promise.all([
        airlinesResponse.json(),
        airportsResponse.json(),
        destinationCitiesResponse.json(),
        destinationContinentsResponse.json(),
        destinationCountriesResponse.json(),
        destinationRegionsResponse.json(),
        destinationStatesResponse.json(),
        shopLocationsResponse.json(),
        countriesResponse.json(),
    ])
    const destinationContinents = destinationContinentsJson.map((obj) => ({
        ...obj, isContinent: true
    }))
    const destinationCountries = destinationCountriesJson.map((obj) => ({
        ...obj, isCountry: true
    }))
    const destinationCities = destinationCitiesJson.map((obj) => ({
        ...obj, isCity: true
    }))
    const destinationTreeList = destinationContinents.map((continent) => ({
        ...continent,
        geoPoint: getContinentGeoPoint(continent.value),
        zoomLevel: getContinentZoomLevel(continent.value),
        countries: destinationCountries
            .filter(({ continentCode }) => continentCode === continent.value)
            .map((country) => ({
                ...country,
                cities: destinationCities
                    .filter(({ countryCode }) => countryCode === country.value)
            }))
    }))
    const destinations = [
        ...destinationContinents,
        ...destinationCountries,
        ...destinationCities,
    ]
    const options = {
        includeScore: true,
        keys: ['name', 'value'],
    }
    const destinationSearch = new Fuse(destinations, options)
    const airlineCodes = airlinesJson.reduce(toKeyValue, {})
    const airportCodes = airportsJson.reduce(toKeyValueObject, {})
    const destinationCityCodes = destinationCitiesJson.reduce(toKeyValue, {})
    const destinationContinentCodes = destinationContinentsJson.reduce(toKeyValue, {})
    const destinationCountryCodes = destinationCountriesJson.reduce(toKeyValue, {})
    const destinationRegionCodes = destinationRegionsJson.reduce(toKeyValue, {})
    const destinationStateCodes = destinationStatesJson.reduce(toKeyValue, {})
    const shopLocations = shopLocationsJson.reduce(toKeyValue, {})
    const countries = countriesJson.reduce(toKeyValue, {})
    return {
        destinations,
        airlineCodes,
        airportCodes,
        destinationCityCodes,
        destinationContinentCodes,
        destinationCountryCodes,
        destinationRegionCodes,
        destinationStateCodes,
        destinationTreeList,
        destinationSearch,
        shopLocations,
        countries,
    }
}
