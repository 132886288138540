import React, { forwardRef } from 'react'

const Notification = forwardRef(({
    message,
    onDismiss,
}, ref) => (
    <div ref={ref} className="max-w-sm w-full mt-1 bg-blue-bright text-white shadow-lg rounded-lg pointer-events-auto">
        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
                <div className="flex items-start">
                    <div className="ml-3 w-0 flex-1">
                        <p className="text-sm leading-5 text-white">
                            {message}
                        </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                        <button type="button" onClick={onDismiss} className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
))

export default Notification
