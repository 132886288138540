import React, { Suspense } from 'react'
import useSearchParamValues from '../hooks/useAddValuesToSearchParams'
import JotformModal from './JotformModal'

const GlobalFormModal = () => {
    const { searchParams, addValuesToSearchParams } = useSearchParamValues()
    const form = searchParams.get('form')

    if (!form) {
        return null
    }

    return (
        <JotformModal
            formId={form}
            onClose={() => addValuesToSearchParams({ form: undefined })}
        />
    )
}

export default (props) => (
    <Suspense>
        <GlobalFormModal {...props} />
    </Suspense>
)
