'use client'

/* eslint-disable react/sort-comp */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
import React from 'react'
import Cookies from 'js-cookie'
import { QueryClient } from 'react-query'
import * as constants from '@connections/constants'
import { NotificationDispatcher } from '@connections/utils'
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar'
import AppStateProvider from './providers/AppStateProvider'
import AppProviders from './AppProviders'
import setupLocales from '../locales/setupLocales'
import fetchCodes from '../util/fetchCodes'
import GlobalFormModal from './utils/GlobalFormModal'
import getStoryblokClient from '../storyblok/getStoryblokClient'
import createGraphqlClient from '../graphql/createGraphqlClient'
import Notifications from './utils/Notifications'

const {
    COOKIE_USER_ID: USER_UID,
} = constants

class AppWithState extends React.Component {
    constructor(props) {
        super(props)
        const {
            locale,
            isPreview,
            notificationDispatcher = NotificationDispatcher.getInstance()
        } = props

        this.i18n = setupLocales(locale)
        this.queryClient = new QueryClient()
        this.userUid = Cookies.get(USER_UID)
        this.notificationDispatcher = notificationDispatcher
        this.storyblokClient = getStoryblokClient(isPreview)
        this.graphqlClient = createGraphqlClient(locale, this.userUid)

        this.state = {
            locale,
            countries: [],
            airportCodes: {},
            airlineCodes: {},
            destinations: [],
            destinationTreeList: [],
            destinationSearch: null,
            destinationCityCodes: {},
            destinationStateCodes: {},
            destinationRegionCodes: {},
            hasErrorLoadingCodes: false,
            destinationCountryCodes: {},
            destinationContinentCodes: {},
        }
    }

    async componentDidMount() {
        await this.loadCodes()
    }

    async componentDidUpdate() {
        const { locale } = this.props
        if (this.i18n._locale !== locale) {
            this.i18n.activate(locale)
            this.storyblokClient.flushCache()
            await this.queryClient.invalidateQueries()
            this.graphqlClient = createGraphqlClient(locale, this.userUid)
            await this.loadCodes()
            this.setState({ locale })
        }
    }

    async loadCodes() {
        try {
            const { locale } = this.props
            const {
                destinations,
                airlineCodes,
                airportCodes,
                destinationCityCodes,
                destinationContinentCodes,
                destinationCountryCodes,
                destinationRegionCodes,
                destinationStateCodes,
                destinationTreeList,
                destinationSearch,
                countries,
            } = await fetchCodes(locale)
            this.setState({
                destinations,
                airlineCodes,
                airportCodes,
                destinationCityCodes,
                destinationContinentCodes,
                destinationCountryCodes,
                destinationRegionCodes,
                destinationStateCodes,
                destinationTreeList,
                destinationSearch,
                countries,
                hasErrorLoadingCodes: false,
            })
        } catch (e) {
            this.setState({ hasErrorLoadingCodes: true })
        }
    }

    render() {
        const { children } = this.props
        const {
            i18n,
            state,
            userUid,
            queryClient,
            graphqlClient,
            storyblokClient,
            notificationDispatcher,
        } = this
        return (
            <AppStateProvider value={state}>
                <AppProviders
                    i18n={i18n}
                    userUid={userUid}
                    queryClient={queryClient}
                    graphqlClient={graphqlClient}
                    storyblokClient={storyblokClient}
                    notificationDispatcher={notificationDispatcher}
                >
                    {children}
                    <ProgressBar
                        shallowRouting
                        options={{ showSpinner: false }}
                        color="rgb(var(--color-primary))"
                    />
                    <GlobalFormModal />
                    <Notifications />
                </AppProviders>
            </AppStateProvider>
        )
    }
}

export default AppWithState
