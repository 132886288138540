import * as Sentry from '@sentry/nextjs'
import { useMemo, useState } from 'react'
import { StatsigClient } from '@statsig/js-client'

export default function useStatsigClient(userUid) {
    const [isLoading, setIsLoading] = useState(true)

    const statsigClient = useMemo(() => {
        const instance = new StatsigClient(
            process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY,
            {
                userID: userUid,
                environment: { tier: process.env.NEXT_PUBLIC_APP_ENV }
            }
        )
        instance
            .initializeAsync()
            .then(() => {
                setIsLoading(false)
            })
            .catch((error) => {
                Sentry.captureException(error)
            })
        return instance
    }, [])

    return {
        isLoading,
        statsigClient,
    }
}
