import { GraphQLClient as GraphqlClient } from 'graphql-request'
import { getSimpleLocale } from '@connections/utils'

export default function createGraphqlClient(locale, userUid, options = {}) {
    return new GraphqlClient(process.env.NEXT_PUBLIC_GRAPHQL_URI, {
        headers: {
            locale: getSimpleLocale(locale),
            'user-uid': userUid,
        },
        ...options
    })
}
