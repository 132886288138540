import React from 'react'
import { I18nProvider } from '@lingui/react'
import { QueryClientProvider } from 'react-query'
import { StatsigProvider } from '@statsig/react-bindings'
import useStatsigClient from '../statsig/useStatsigClient'
import NotificationsProvider from './providers/NotificationsProvider'
import GraphqlClientProvider from './providers/GraphqlClientProvider'
import StoryblokClientProvider from './providers/StoryblokClientProvider'

const AppProviders = ({
    i18n,
    userUid,
    children,
    queryClient,
    graphqlClient,
    storyblokClient,
    notificationDispatcher,
}) => {
    const { statsigClient } = useStatsigClient(userUid)

    return (
        <StatsigProvider client={statsigClient}>
            <GraphqlClientProvider value={graphqlClient}>
                <StoryblokClientProvider value={storyblokClient}>
                    <QueryClientProvider client={queryClient}>
                        <I18nProvider i18n={i18n}>
                            <NotificationsProvider dispatcher={notificationDispatcher}>
                                {children}
                            </NotificationsProvider>
                        </I18nProvider>
                    </QueryClientProvider>
                </StoryblokClientProvider>
            </GraphqlClientProvider>
        </StatsigProvider>
    )
}

export default AppProviders
